






























































































































import Vue, { PropType } from 'vue'
import Separator from '../shared/Separator.vue'
import CurrencyInput from '../shared/CurrencyInput.vue'
import { Price, Tax, TaxTypeEnum } from '../../../../shared/types/types'
import { CurrencyEnum } from '../../../../shared/types/types'
import { validateComparePrice, validatePrice } from '@/lib/validation/product'
import { ErrorObject } from '../../types'
import { formatPrice } from '@/lib/utils'

export default Vue.extend({
  name: 'Pricing',
  components: { Separator, CurrencyInput },
  props: {
    price: {
      type: Object as PropType<Price>,
      default: () => {
        return {
          amount: 0,
          currency: CurrencyEnum.Usd,
        }
      },
    },
    discountedPrice: {
      type: Object as PropType<Price>,
      default: () => {
        return {
          amount: 0,
          currency: CurrencyEnum.Usd,
        }
      },
    },
    internalPrice: {
      type: Object as PropType<Price>,
      default: () => {
        return {
          amount: 0,
          currency: CurrencyEnum.Usd,
        }
      },
    },
    upsellPrice: {
      type: Object as PropType<Price>,
      default: () => {
        return {
          amount: 0,
          currency: CurrencyEnum.Usd,
        }
      },
    },
    discountedUpsellPrice: {
      type: Object as PropType<Price>,
      default: () => {
        return {
          amount: 0,
          currency: CurrencyEnum.Usd,
        }
      },
    },
    internalUpsellPrice: {
      type: Object as PropType<Price>,
      default: () => {
        return {
          amount: 0,
          currency: CurrencyEnum.Usd,
        }
      },
    },
    packagePrice: {
      type: Object as PropType<Price>,
      default: () => {
        return {
          amount: 0,
          currency: CurrencyEnum.Usd,
        }
      },
    },
    discountedPackagePrice: {
      type: Object as PropType<Price>,
      default: () => {
        return {
          amount: 0,
          currency: CurrencyEnum.Usd,
        }
      },
    },
    internalPackagePrice: {
      type: Object as PropType<Price>,
      default: () => {
        return {
          amount: 0,
          currency: CurrencyEnum.Usd,
        }
      },
    },
    comparePrice: {
      type: Object as PropType<Price>,
      default: () => {
        return {
          amount: 0,
          currency: CurrencyEnum.Usd,
        }
      },
    },
    chargeTax: {
      type: Boolean,
      default: false,
    },
    disableAutomaticDiscount: {
      type: Boolean,
      default: false,
    },
    taxes: {
      type: Array as PropType<Tax[]>,
      default: () => [],
    },
    defaultActiveTaxIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      error: {
        price: { isValid: true, messageList: [''] } as ErrorObject,
        comparePrice: { isValid: true, messageList: [''] } as ErrorObject,
      },
      activeTaxIds: this.defaultActiveTaxIds.slice(),
    }
  },
  watch: {
    defaultActiveTaxIds: {
      handler() {
        this.activeTaxIds = this.defaultActiveTaxIds
      },
    },
  },
  methods: {
    /**
     * Handler to emit price object
     * @param value - amount defined by the user
     * @param field which is the name of the pricing field
     */
    updatePrice(value: string, field: string) {
      let priceObj = {} as Price
      const fieldName = field
      switch (field) {
        case 'price':
          priceObj = {
            currency: this.price.currency,
            amount: parseFloat(value),
          }
          this.error.price = validatePrice(this.price)
          break
        case 'discountedPrice':
          priceObj = {
            currency: this.discountedPrice.currency,
            amount: parseFloat(value),
          }
          this.error.price = validatePrice(this.discountedPrice)
          break
        case 'internalPrice':
          priceObj = {
            currency: this.internalPrice.currency,
            amount: parseFloat(value),
          }
          this.error.price = validatePrice(this.internalPrice)
          break
        case 'upsellPrice':
          priceObj = {
            currency: this.upsellPrice.currency,
            amount: parseFloat(value),
          }
          this.error.price = validatePrice(this.upsellPrice)
          break
        case 'discountedUpsellPrice':
          priceObj = {
            currency: this.discountedUpsellPrice.currency,
            amount: parseFloat(value),
          }
          this.error.price = validatePrice(this.discountedUpsellPrice)
          break
        case 'internalUpsellPrice':
          priceObj = {
            currency: this.internalUpsellPrice.currency,
            amount: parseFloat(value),
          }
          this.error.price = validatePrice(this.internalUpsellPrice)
          break
        case 'packagePrice':
          priceObj = {
            currency: this.packagePrice.currency,
            amount: parseFloat(value),
          }
          this.error.price = validatePrice(this.packagePrice)
          break
        case 'discountedPackagePrice':
          priceObj = {
            currency: this.discountedPackagePrice.currency,
            amount: parseFloat(value),
          }
          this.error.price = validatePrice(this.discountedPackagePrice)
          break
        case 'internalPackagePrice':
          priceObj = {
            currency: this.internalPackagePrice.currency,
            amount: parseFloat(value),
          }
          this.error.price = validatePrice(this.internalPackagePrice)
          break
      }
      this.$emit('update-price', priceObj, fieldName)
    },
    /**
     * Handler to emit compare price object
     * @param value - amount defined by the user
     * @param price - price object of the variant
     */
    updateComparePrice(value: string, comparePriceObj: Price) {
      let price = {
        currency: comparePriceObj.currency,
        amount: parseFloat(value),
      }
      this.error.price = validateComparePrice(this.price)
      this.$emit('update-compare-price', price)
    },
    /**
     * Handler to emit charge tax
     */
    updateChargeTax(value: boolean) {
      this.$emit('update-charge-tax', value)
    },
    /**
     * Handler to emit disable automatic discount
     */
    updateDisableAutomaticDiscount(value: boolean) {
      this.$emit('update-disable-automatic-discount', value)
    },
    /**
     * Verify if the field has error
     *
     * @param - data - returned as error object after validation
     */
    getErrorType(data: ErrorObject): string {
      return data.isValid ? '' : 'is-danger'
    },
    /**
     * get the error message corresponding to the field.
     *
     * @param - data - returned as error object after validation
     */
    getErrorMessage(data: ErrorObject): string {
      return data.isValid ? '' : data.messageList[0] || ''
    },
    /**
     * Get the tax value
     *
     * @param - tax - Tax
     */
    getTaxValue(tax: Tax) {
      switch (tax.type) {
        case TaxTypeEnum.Percentage:
          return `${tax.title} - ${tax.percentage}%`
        case TaxTypeEnum.FlatRate:
          return `${tax.title} - ${formatPrice(tax.flatRate as Price)}`
      }
    },
  },
})
