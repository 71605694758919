












































import { validateQuantity } from '@/lib/validation/product'
import { ErrorObject } from '@/types'
import Vue from 'vue'
import Separator from '../shared/Separator.vue'

export default Vue.extend({
  name: 'Inventory',
  components: { Separator },
  props: {
    sku: {
      type: String,
      default: '',
    },
    trackQuantity: {
      type: Boolean,
      default: false,
    },
    isOversellable: {
      type: [Boolean, String],
      default: false,
    },
    quantity: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      barcode: '',
      error: {
        quantity: { isValid: true, messageList: [''] } as ErrorObject,
      },
    }
  },
  methods: {
    /**
     * Handler to emit sku value of the variant
     * @param value - sku defined by the user
     */
    updateSku(value: string) {
      this.$emit('update-sku', value)
    },

    /**
     * Handler to emit track quantity value of the variant
     * @param value - trackQuantity defined by the user
     */
    updateTrackQuantity(value: boolean) {
      this.$emit('update-track-quantity', value)
    },

    /**
     * Handler to emit isOversellable value of the variant
     * @param value - isOversellable defined by the user
     */
    updateIsOversellable(value: boolean) {
      this.$emit('update-isoversellable', value)
    },

    /**
     * Handler to emit quantity value of the variant
     * @param value - quantity defined by the user
     */
    updateQuantity(value: number) {
      this.error.quantity = validateQuantity(value)
      this.$emit('update-quantity', value)
    },

    /**
     * Verify if the field has error
     *
     * @param - data - returned as error object after validation
     */
    getErrorType(data: ErrorObject): string {
      return data.isValid ? '' : 'is-danger'
    },

    /**
     * Get the error messages for field
     *
     * @param - data - returned as error object after validation
     */
    getErrorMessage(data: ErrorObject): string {
      return data.isValid ? '' : data.messageList[0] || ''
    },
  },
})
