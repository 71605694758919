














































































import Vue from 'vue'
import Separator from '../shared/Separator.vue'
import { VariantTypeEnum } from '../../../../shared/types/types'

export default Vue.extend({
  name: 'Shipping',
  components: { Separator },
  props: {
    variantType: {
      type: String,
      default: VariantTypeEnum.Virtual,
    },
  },
  data() {
    return {
      weight: null,
    }
  },
  methods: {
    /**
     * Handler to emit variant type value of the variant
     * @param value - variantType defined by the user
     */
    updateVariantType(event: string) {
      const eventToEmit =
        event === 'physical'
          ? VariantTypeEnum.Physical
          : VariantTypeEnum.Virtual
      this.$emit('update-variant-type', eventToEmit)
    },
  },
})
