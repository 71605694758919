import { errorMessage } from '@/constants/message'
import { ErrorObject } from '@/types'
import {
  Maybe,
  Price,
  ProductInput,
  Variant,
  VariantInput,
} from '../../../../shared/types/types'

// TODO Add specs for the functions below #167

/**
 * Validate product Object
 * Checks for valid product title
 * Checks for valid option lables and combinations inside variants
 * Checks for every variant of the product if it is valid
 * If any of the above checks are not valid, the isValid is set to false
 *
 * @param product - product that needs to be validated
 * @returns ErrorObject - contains isValid or not along with error message list
 */
export const validateProduct = (product: ProductInput): ErrorObject => {
  const error: ErrorObject = {
    isValid: true,
    messageList: [],
  }
  if (product.title) {
    error.isValid = true
    product.variants.forEach((variant) => {
      const optionLabels = product.optionLabels ? product.optionLabels : []
      const validateVariantObj = validateVariant(variant, optionLabels)
      if (!validateVariantObj.isValid) {
        error.isValid = false
        error.messageList = [
          ...error.messageList,
          ...validateVariantObj.messageList,
        ]
      }
    })
  } else {
    error.isValid = false
    error.messageList.push(errorMessage.TITLE_EMPTY)
  }
  return error
}

/**
 * Validate variant Object
 * Checks for Valid title for the variant
 * Checks for valid quantity of the variant
 * Checks for valid price of the variant
 * Checks for valid options of the variant
 * If any of the above checks fails, the variant is invalid
 *
 * @param variant - variant that needs to be validated
 * @param optionLabels - option labels available for the product
 * @returns ErrorObject - contains isValid or not along with error message list
 */
export const validateVariant = (
  variant: VariantInput,
  optionLabels: Maybe<string>[] = []
): ErrorObject => {
  const error: ErrorObject = {
    isValid: true,
    messageList: [],
  }
  if (
    validateTitle(variant.title).isValid &&
    validateQuantity(variant.quantity).isValid &&
    validatePrice(variant.price).isValid &&
    validateOptions(optionLabels, variant).isValid &&
    variant.variantType &&
    validateQuantity(variant.quantity).isValid &&
    variant.chargeTax !== undefined &&
    variant.isOversellable !== undefined &&
    variant.trackQuantity !== undefined
  ) {
    error.isValid = true
  } else {
    error.isValid = false
    if (!validateTitle(variant.title).isValid) {
      error.messageList = [
        ...error.messageList,
        ...validateTitle(variant.title).messageList,
      ]
    }
    if (!validatePrice(variant.price).isValid) {
      error.messageList = [
        ...error.messageList,
        ...validatePrice(variant.price).messageList,
      ]
    }
    if (!validateQuantity(variant.quantity).isValid) {
      error.messageList = [
        ...error.messageList,
        ...validateQuantity(variant.quantity).messageList,
      ]
    }
    if (!validateOptions(optionLabels, variant).isValid) {
      error.messageList = [
        ...error.messageList,
        ...validateOptions(optionLabels, variant).messageList,
      ]
    }
    if (!variant.variantType) {
      error.messageList.push(errorMessage.VARIANT_TYPE_EMPTY)
    }
  }
  return error
}
/**
 * Validate Title of Product
 *
 * @param title - title of the product
 * @returns ErrorObject - contains isValid or not along with error message list
 */
export const validateTitle = (title: string): ErrorObject => {
  const error: ErrorObject = {
    isValid: true,
    messageList: [],
  }
  if (!title) {
    error.isValid = false
    error.messageList.push(errorMessage.TITLE_EMPTY)
  }
  return error
}
/**
 * Validate price of Product
 *
 * @param price - price of the product
 */
export const validatePrice = (price: Price): ErrorObject => {
  const error: ErrorObject = {
    isValid: true,
    messageList: [],
  }
  if (price.amount === undefined || price.amount === null) {
    error.isValid = false
    error.messageList.push(errorMessage.PRICE_EMPTY)
  } else if (price.amount < 0) {
    error.isValid = false
    error.messageList.push(errorMessage.PRICE_INVALID)
  }
  return error
}
/**
 * Validate compare price of the product
 *
 * @param comparePrice
 * @returns ErrorObject - contains isValid or not along with error message list
 */
export const validateComparePrice = (comparePrice: Price): ErrorObject => {
  const error: ErrorObject = {
    isValid: true,
    messageList: [],
  }
  if (comparePrice.amount !== undefined && comparePrice.amount !== null) {
    if (comparePrice.amount < 0) {
      error.isValid = false
      error.messageList.push(errorMessage.PRICE_INVALID)
    }
  }
  return error
}
/**
 * Validate quantity
 *
 * @param quantity - numerical value for the quantity
 */
export const validateQuantity = (quantity: number): ErrorObject => {
  const error: ErrorObject = {
    isValid: true,
    messageList: [],
  }
  if (quantity === undefined || quantity === null) {
    error.isValid = false
    error.messageList.push(errorMessage.QUANTITY_EMPTY)
  } else if (quantity < 0) {
    error.isValid = false
    error.messageList.push(errorMessage.QUANTITY_INVALID)
  }
  return error
}
/**
 * Validate Options Values based on the option labels
 *
 * @param optionLabels - list of option labels
 * @param variant - variant that needs to be validated
 * @returns ErrorObject - contains isValid or not along with error message list
 */
export const validateOptions = (
  optionLabels: Maybe<string>[] = [],
  variant: Variant
): ErrorObject => {
  let error: ErrorObject = {
    isValid: true,
    messageList: [],
  }
  if (optionLabels && optionLabels[0] != 'Title') {
    for (let i = 1; i <= optionLabels.length; i++) {
      switch (i) {
        case 1:
          error = validateOption(variant.option1)
          break
        case 2:
          error = validateOption(variant.option2)
          break
        case 3:
          error = validateOption(variant.option3)
          break
      }
    }
  }
  return error
}
/**
 * Validate single option value of option values
 *
 * @param Optionvalue - an option value that needs to be validated
 * @returns ErrorObject - contains isValid or not along with error message list
 */
export const validateOption = (
  optionValue: string | null | undefined
): ErrorObject => {
  const error: ErrorObject = {
    isValid: true,
    messageList: [],
  }
  if (!optionValue) {
    error.isValid = false
    error.messageList.push(errorMessage.OPTION_VALUE_EMPTY)
  }

  return error
}
