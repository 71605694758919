/**
 * Success message for the user action
 */
export const SuccessMessage = {
  CREATE_PRODUCT_SUCCESS_MSG: 'Product is created successfully.',
  UPDATE_PRODUCT_SUCCESS_MSG: 'Product is updated successfully.',
  DELETE_PRODUCT_SUCCESS_MSG: 'Product is deleted successfully.',
  CREATE_VARIANT_SUCCESS_MSG: 'Variant is created successfully.',
  UPDATE_VARIANT_SUCCESS_MSG: 'Variant is updated successfully.',
  DELETE_VARIANT_SUCCESS_MSG: 'Variant is deleted successfully.',
}

/**
 * Error messages to be shown to the user
 */
export const errorMessage = {
  GENERIC_ERR_MSG: 'Something went wrong! Try again later.',
  PRODUCT_UPDATE_FAILED: 'Product Update Failed',
  PRICE_EMPTY: 'Price cannot be empty.',
  OPTION_VALUE_EMPTY: 'Option values cannot be empty.',
  TITLE_EMPTY: 'Title cannot be empty',
  QUANTITY_EMPTY: 'Quantity cannot be empty',
  VARIANT_TYPE_EMPTY: 'Variant Type cannot be empty',
  PRICE_INVALID: 'Price entered is invalid.',
  QUANTITY_INVALID: 'Quantity entered is invalid.',
  VARIANT_OPTIONS_EMPTY: 'Variant option values cannot be empty',
  EMPTY_BULK_SELECTION: 'Please select at least one element.',
}
