




















import Vue from 'vue'
export default Vue.extend({
  name: 'Message',
  props: {
    message: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      isActive: true,
    }
  },
})
